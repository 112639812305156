<template>
  <div
    class="field"
    :class="{ 'is-horizontal': labelForInput, 'is-vertical': !labelForInput }"
  >
    <div class="field-label is-normal has-text-left">
      <label
        ref="label"
        class="label"
      >
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div
        class="field"
        :class="fieldClasses"
      >
        <slot name="fields"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-field',

  props: {
    label: {
      type: String,
      default: ''
    },

    labelForInput: {
      type: Boolean,
      default: true
    },

    fieldClasses: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
.flex {
  display: flex;
  align-items: center;
}
.is-borderless {
  padding-left: 0;
  border-color: transparent;
  &:hover {
    border-color: transparent;
  }
}

.field.is-vertical {
  .label {
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: flex;
    align-items: center;

    .field-label {
      padding-top: unset;
      flex-basis: 150px;
      flex-grow: 0;
    }
  }
}
@media screen and (max-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: block;

    .field-label {
      flex-basis: unset;
      flex-grow: unset;
      padding-top: 0.375em;
      margin-bottom: 0.5rem;
    }
  }
}
</style>