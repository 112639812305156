<template>
  <div>
    <TopBar />
    <slot></slot>
    <Footer v-if="!hideFooter" />
  </div>
</template>

<script>
const TopBar = () => import(/* webpackChunkName: "pageLayout" */ '@/components/MainSiteElements/Frontend/TopBar.vue')
const Footer = () => import(/* webpackChunkName: "pageLayout" */ '@/components/MainSiteElements/Frontend/Footer.vue')
export default {
  name: 'pageLayout',

  props: {
    hideFooter: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Footer,
    TopBar
  }
}
</script>

<style lang="scss" scoped>
</style>