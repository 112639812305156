<template>
  <div>
    <div class="title is-4" v-html="$t('Components.AccountControls.SetPassword.Mail_Title')"></div>
    <Message class="is-danger" v-if="errorMessage" :message="errorMessage">
    </Message>
    <div class="content">
      <p v-html="$t('Components.AccountControls.SetPassword.Text_Notification')"></p>
    </div>
    <div>
      <ui-base-input-field v-model="form.password" :labelForInput="false" :error="passwordErrors" :label="$t('Components.AccountControls.SetPassword.Label_Password')" fieldType="password"
        @input="$v.form.password.$touch()" @blur="$v.form.password.$touch()" required />
      <ui-base-input-field v-model="form.confirmPassword" :labelForInput="false" :error="confirmPasswordErrors" fieldType="password" :label="$t('Components.AccountControls.SetPassword.Label_RepeatPassword')
        " @input="$v.form.confirmPassword.$touch()" @blur="$v.form.confirmPassword.$touch()" required />
      <BaseFieldTemplate :labelForInput="false" :label="$t('Components.AccountControls.SetPassword.Label_ConfirmationCode')
        " :required="true">
        <template v-slot:fields>
          <div class="level is-inline-flex input-codes is-mobile">
            <div class="level-item">
              <input type="text" ref="codePart1" @keyup="checkCodePart1" size="2" max="2" maxlength="2" name="codePart1" class="input has-text-centered"
                :class="[{ 'is-danger': failCode === -1 || failCode === 400 }]" />
            </div>
            <div class="level-item">
              <font-awesome-icon :icon="['far', 'minus']" />
            </div>
            <div class="level-item">
              <input type="text" ref="codePart2" @keyup="checkCodePart2" size="2" max="2" maxlength="2" name="codePart2" class="input has-text-centered"
                :class="[{ 'is-danger': failCode === -1 || failCode === 400 }]" />
            </div>
            <div class="level-item">
              <font-awesome-icon :icon="['far', 'minus']" />
            </div>
            <div class="level-item">
              <input type="text" ref="codePart3" @keyup="checkCodePart3" size="2" max="2" maxlength="2" name="codePart3" class="input has-text-centered"
                :class="[{ 'is-danger': failCode === -1 || failCode === 400 }]" />
            </div>
          </div>
          <p v-if="errorCodeMessage">
            <span class="help is-danger">
              {{ errorCodeMessage }}
            </span>
          </p>
        </template>
      </BaseFieldTemplate>

      <div class="level is-mobile has-padding-top">
        <div class="level-left">
          <router-link :to="{ name: 'my-account-forgotpassword' }" class="has-icon">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'angle-left']" />
            </span>
            <span class="has-icon" v-html="$t('Form.Control.Back')"></span>
          </router-link>
        </div>
        <div class="level-right">
          <button @click="handleSubmit" :disabled="Boolean($v.$error) || isProcessing" class="button is-success" :class="[
            {
              'is-disabled': Boolean($v.$error) || isProcessing,
              'is-loading': isProcessing,
            },
          ]">
            <span v-html="$t('Components.AccountControls.SetPassword.Button_Save')"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import loginProvider from '@/providers/login'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import encryptString from '@/mixins/encryptString'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate.vue'

const touchMap = new WeakMap()

export default {
  name: 'components-set-password',

  components: {
    BaseFieldTemplate,
    Message: () =>
      import(
        /* webpackChunkName: "component-account-controls-setpassword" */ '@/components/UI/Message'
      ),
  },

  mixins: [encryptString],

  data() {
    return {
      form: {
        password: '',
        confirmPassword: '',
        code: '',
      },
      failCode: 0,
      passwordsNotEqual: false,
      isProcessing: false,
      errorCodeMessage: '',
    }
  },

  computed: {
    ...mapState('accountStore', ['confirmationToken']),

    errorMessage() {
      if (
        this.failCode === -1 ||
        this.failCode === -3 ||
        this.failCode === -4
      ) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode-1'
        )
      } else if (this.failCode === -2) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode-2'
        )
      } else if (this.failCode === 400) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode400'
        )
      } else if (this.failCode === 429) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode429'
        )
      } else if (this.failCode === 500) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode500'
        )
      } else if (this.failCode === 501) {
        return this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorCode501'
        )
      }
      return ''
    },

    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      if (!this.$v.form.password.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t(
              'Components.AccountControls.SetPassword.Label_Password'
            ),
          })
        )
      }
      if (!this.$v.form.password.minLength) {
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 6 }))
      }
      if (!/(?=.*[A-Z])/.test(this.form.password)) {
        errors.push(this.$t('Form.InputErrors.uppercaseCharacter'))
      }
      if (!/(?=.*\d)/.test(this.form.password)) {
        errors.push(this.$t('Form.InputErrors.numberCharacter'))
      }
      return errors
    },

    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirmPassword.$dirty) {
        return errors
      }
      if (!this.$v.form.confirmPassword.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t(
              'Components.AccountControls.SetPassword.Label_ConfirmationCode'
            ),
          })
        )
      } else if (!this.$v.form.confirmPassword.sameAsPassword) {
        errors.push(this.$t('Form.InputErrors.NotSamePasswords'))
      }
      return errors
    },
  },

  /**
   * Form validation rules
   */
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  methods: {
    ...mapMutations('accountStore', ['setConfirmationToken']),

    onCodeValidated() {
      this.errorCodeMessage = ''
      if (this.form.code === '') {
        this.errorCodeMessage = this.$t('Form.InputErrors.RequiredField', {
          fieldLabel: this.$t(
            'Components.AccountControls.SetPassword.Label_ConfirmationCode'
          ),
        })
      } else if (this.failCode === -1) {
        this.errorCodeMessage = this.$t(
          'Components.AccountControls.SetPassword.Message_ErrorConfirmationCode'
        )
      }
    },

    async handleSubmit() {
      let self = this
      if (!this.isProcessing) {
        this.failCode = 0
        this.isProcessing = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
          // //console.info(e)
          this.isProcessing = false
        } else {
          // do your submit logic here
          this.submitStatus = 'PENDING'
        }

        let encryptedPassword = this.encryptPassword(this.form.confirmPassword)

        // Send request e-mail
        await loginProvider.methods.changePassword(this.confirmationToken, this.form.code, encryptedPassword)
          .then((response) => {
            this.failCode = response.data
            if (this.failCode > 0) {
              self.$emit('nextAction')
              self.setConfirmationToken('')
            } else {
              this.isProcessing = false
            }
          })
          .catch((e) => {
            this.failCode = e.response.status
            this.isProcessing = false
          })
          .finally(() => {
            self = null
          })
      }
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    checkCodePart3() {
      this.updateCode()
    },
    updateCode() {
      let output = ''
      output += this.$refs.codePart1 ? this.$refs.codePart1.value.trim() : ''
      output += this.$refs.codePart2 ? this.$refs.codePart2.value.trim() : ''
      output += this.$refs.codePart3 ? this.$refs.codePart3.value.trim() : ''
      this.form.code = output
      this.onCodeValidated()
    },
  },
}
</script>

<style lang="scss" scoped></style>
