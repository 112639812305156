<template>
  <div class="login-fields-component">
    <div class="title is-4 " v-html="$t('Components.AccountControls.SetPasswordConfirmation.Main_Title')">
    </div>
    <Message class="is-success">
      <template>
        <font-awesome-icon :icon="['fa', 'check']" class="has-margin-right" />
        {{ $t('Components.AccountControls.SetPasswordConfirmation.Message_Success') }}
      </template>
    </Message>
    <div class="has-margin-bottom" v-html="$t('Components.AccountControls.SetPasswordConfirmation.Text_Notification')">
    </div>
    <div class="has-text-right has-padding">
      <router-link tag="button" class="button is-success" :to="{ name: 'my-account-login' }" v-html="$t('Components.AccountControls.SetPasswordConfirmation.Button_Login')"></router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
const Message = () => import(/* webpackChunkName: "component-account-controls-setpassword-confirmation" */ '@/components/UI/Message')

export default {
  name: 'set-new-password-confirmation',

  components: {
    Message
  }
}
</script>


